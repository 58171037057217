/**
 * Created by Tomasz Kotlarek (ZICHER) on 11.09.2017.
 */

define(
  'application/main',[
    "application/options",
    "jquery",
    "bootstrap"
  ],
  function (
    appOptions,
    $,
    bootstrap
  ) {
    // helper variables
    var $window = $(window);
    var $document = $(document);
    var $htmlBody = $("html, body");
    var $html = $("html");
    var $body = $("body");

    // language
    var locale = $html.attr("lang");

    /**
     *
     * @type {{run: run, moveToFragment: moveToFragment}}
     */
    var application = {
      /**
       *
       * @returns {application}
       */
      run: function () {
        this
          .moveToFragment();

        $document.trigger("domset", [{dom: $body}]);

        return this;
      },

      /**
       *
       * @returns {application}
       */
      moveToFragment: function () {
        $document.on("moveToFragment", function (event, data) {
          setTimeout(function () {
            var offset = data.$target.offset();

            if (offset) {
              // minus fixed menu height minus target top margin
              var height = 0; //$(".js-move-to-fragment-header").height();
              var margin = 0; //parseInt(window.getComputedStyle(document.getElementById($target.attr("id"))).getPropertyValue("margin-top"));
              var scrollTo = offset.top - height - margin;

              $("html, body").animate({scrollTop: scrollTo}, data.source === "load" ? 0 : 500);
            }
          }, 20);
        });

        $document.on("click", 'a[href*="#"]', function (event) {
          event.preventDefault();
          var $this = $(this);
          var href = $this.attr("href");
          var hash = href.substr(href.indexOf("#"));
          history.pushState(null, $this.text(), hash);

          $document.trigger("moveToFragment", [{
            source: "click",
            $target: $(hash)
          }]);
        });

        $document.trigger("moveToFragment", [{
          source: "load",
          $target: $(":target")
        }]);

        return this;
      }
    };

    application.run();

    return application;
  }
);
